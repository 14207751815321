<template>
	<div class="bg-Hight">
		<!-- 筛选 -->
		<div class="crud">
			<ul class="crud-list">
				<li>
					<el-button size="mini" @click="getList">刷新</el-button>
				</li>
				<li>
					<el-button @click="exportexl" type="success" class="el-white" size="mini">导出工单</el-button>
				</li>
				<li>
					<el-input @clear="clearable('name')" type="text" clearable v-model="form.name"
						placeholder="请输入工单标题">
					</el-input>
				</li>
				<li>
					<el-select clearable @clear="clearable('status')" v-model="form.status" placeholder="状态">
						<el-option label="申请退单中" :value="6"></el-option>
						<el-option label="申请退单通过" :value="7"></el-option>
						<el-option label="申请退单驳回" :value="8"></el-option>
					</el-select>
				</li>
				<li>
					<el-select clearable v-model="form.special" @clear="clearable('special')" placeholder="请选择特殊情形">
						<el-option label="一般工单" :value="0"></el-option>
						<el-option label="重点工单" :value="1"></el-option>
						<el-option label="紧急工单" :value="2"></el-option>
						<el-option label="预警工单" :value="3"></el-option>
					</el-select>
				</li>
				<li>
					<el-select filterable clearable @clear="clearable('appeal_cate_id')" v-model="form.appeal_cate_id"
						placeholder="请选择诉求类型">
						<el-option v-for="item in sqoptions" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</li>
				<li>
					<el-button @click="search" color="#5473E8" class="el-white" style="color: white" size="mini">搜索
					</el-button>
				</li>
				<li>
					<el-button @click="reset" size="mini" class="el-white" type="danger">清空</el-button>
				</li>

				<li>
					<el-button @click="bindscreen" size="mini"><i class="icon iconfont icon-sousuo"></i>高级筛选</el-button>
				</li>
			</ul>
		</div>
		<!-- 表格列表 -->
		<div class="table">
			<div class="table-switch" @click="shows" v-if="$store.state.userinfo.roles==2">
				<i v-if="hide" class="icon iconfont icon-fanhui2"></i>
				<i v-else class="icon iconfont icon-guanbi"></i>
			</div>
			<el-table element-loading-text="加载中..." v-loading="tableloading" :max-height="maxheight" border
				:data="tableData" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="80" />
				<el-table-column type="index" label="序号" />
				<el-table-column property="name" label="状态" width="150">
					<template #default="scope">
						<el-tag v-if="scope.row.status==-1" type="warning">待派单</el-tag>
						<el-tag v-if="scope.row.status==0" type="warning">待签收</el-tag>
						<el-tag v-if="scope.row.status==1" type="danger">处理中</el-tag>
						<el-tag v-if="scope.row.status==2" type="danger">申请联合办理中</el-tag>
						<el-tag v-if="scope.row.status==3" type="danger">联合办理中</el-tag>
						<el-tag v-if="scope.row.status==5" type="success">已完成</el-tag>
						<el-tag v-if="scope.row.status==6" type="danger">申请退单中</el-tag>
						<el-tag v-if="scope.row.status==7" type="danger">申请退单通过</el-tag>
						<el-tag v-if="scope.row.status==8" type="danger">申请退单驳回</el-tag>
					</template>
				</el-table-column>
				<el-table-column v-if="$store.state.userinfo.roles==1" property="order_number" label="工单归属单位"
					width="200">
					<template #default="scope">
						{{scope.row.admin?scope.row.admin.account:''}}
					</template>
				</el-table-column>
				<el-table-column property="order_number" label="工单编号" width="200" />
				<el-table-column property="name" label="工单标题" width="200" />
				<el-table-column sortable property="start_time" label="交办时间" width="150" />
				<el-table-column sortable property="return_time" label="申请退单时间" width="150" />
				<el-table-column sortable property="completion_time" label="应办结时间" width="150" />
				<el-table-column sortable property="apply_delay_time" label="延期时间" width="150" />
				<el-table-column property="name" label="诉求主体">
					<template #default="scope">
						<p v-if="scope.row.subject==1">企业</p>
						<p v-if="scope.row.subject==0">个人</p>
					</template>
				</el-table-column>
				<el-table-column property="special" label="特殊情形" width="150">
					<template #default="scope">
						<p v-if="scope.row.special==0">一般工单</p>
						<p v-if="scope.row.special==1">重点工单</p>
						<p v-if="scope.row.special==2">紧急工单</p>
					</template>

				</el-table-column>
				<el-table-column property="appeal_cate" label="诉求类型" />
				<el-table-column property="business_cate" label="业务分类" />
				<el-table-column property="content" label="具体地址">
					<template #default="scope">
						<el-popover placement="top-start" title=" " :width="200" trigger="hover"
							:content="scope.row.address">
							<template #reference>
								<div class="pubcontent">{{scope.row.address}}</div>
							</template>
						</el-popover>
					</template>
				</el-table-column>
				<el-table-column property="regionalTotal" label="区域网络总长" width="150">
					<template #default="scope">
						<span v-if="scope.row.regionalTotal" v-for="(items,indexs) in scope.row.regionalTotal"
							:key="indexs">{{items.name}},</span>
					</template>
				</el-table-column>
				<el-table-column property="regional" label="区域网格长" width="150">
					<template #default="scope">
						{{scope.row.regional?scope.row.regional.name:''}}
					</template>
				</el-table-column>
				<el-table-column property="gridChief" label="网格长" width="150">
					<template #default="scope">
						{{scope.row.gridChief?scope.row.gridChief.name:''}}
					</template>
				</el-table-column>
				<el-table-column property="gridMember" label="网格员" width="150">
					<template #default="scope">
						{{scope.row.gridMember?scope.row.gridMember.name:''}}
					</template>
				</el-table-column>
				<el-table-column v-if="hide&&$store.state.userinfo.roles==2" property="address" label="操作" fixed="right"
					width="300">
					<template #default="scope">
						<div style="padding-top: 10px;">
							<el-button v-if="scope.row.status==6" class="btn-b" @click="examine(scope.row.order_sn,1)"
								size="mini">同意</el-button>
							<el-button v-if="scope.row.status==6" class="btn-b" @click="examine(scope.row.order_sn,2)"
								size="mini">驳回
							</el-button>
							<el-button v-if="scope.row.status==6" class="btn-b"
								@click="seereason(scope.row.return_reason,'查看退单原因')" size="mini">查看退单原因
							</el-button>

							<el-button v-if="scope.row.status==7" class="btn-b" @click="selectuser(scope.row.order_sn)"
								size="mini">重新派单
							</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<!--分页 -->
			<div class="pages">
				<el-pagination background :current-page="form.page" :page-sizes="[15, 20, 30, 40,50,100]"
					:page-size="form.limit" layout="sizes, prev, pager, next, jumper" :total="total"
					@size-change="handleSizeChange" @current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</div>
		<!-- 高级筛选 -->
		<Screen ref="screen"></Screen>
		<!-- 用户列表 -->
		<Selectuser ref="selectuser" @SubmitForm="getList"></Selectuser>
	</div>
</template>

<script>
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import {
		ElLoading
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/work";
	import Screen from '@/components/screen.vue'
	import Selectuser from './selectuser.vue'
	export default {
		name: 'users',
		components: {
			Screen,
			Selectuser,
		},
		data() {
			return {
				hide: true,
				sqoptions: [],

				maxheight: document.documentElement.clientHeight - 232 + 'px',
				tableData: [],
				form: {
					page: 1,
					limit: 15,
					status: "",
					district_id: '',
					name: '',
					special: '',
					appeal_cate_id: '',
					order_sn: '',
					subject: '',
					business_cate_id: '',
					start_time: '',
					completion_time: '',
					tab: 2,
					order_number: '',
					position_type: '',
					position_name: '',
					type: ''
				},
				idlist: []
			}
		},
		created() {
			this.getListsq()
			this.getList()
			// 高级筛选
			let that = this
			this.$EventBus.on("FSUBMITFORM", (data) => {
				that.form.page = 1
				that.form = data
				that.getList()
			})
		},

		methods: {
			// 操作显示隐藏
			shows() {
				this.hide = !this.hide
			},
			// 高级筛选
			bindscreen() {
				this.$refs.screen.show(this.form)
			},
			// 导出工单
			exportexl() {
				if (this.tableData.length > 0) {
					const loading = ElLoading.service({
						lock: true,
						text: '正在导出工单,请稍等...',
						background: 'rgba(0, 0, 0, 0.7)',
					})
					this.form.id = this.idlist.join(",")
					this.$http.post(api.daochu, this.form)
						.then((res) => {
							if (res.code == 1) {
								window.location.href = res.data
								loading.close()
							}
						})
				} else {
					ElMessage({
						message: '表格暂无可导出数据!',
						type: 'warning',
					})
				}
			},
			// 用户列表
			selectuser(order_sn) {
				this.$refs.selectuser.show(order_sn)
			},
			// 退单审核
			examine(order_sn, num) {
				ElMessageBox.confirm(
						'是否确定' + (num == 1 ? '同意' : '驳回'),
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						this.$http.post(api.ChargebackAudit, {
								order_sn: order_sn,
								back_status: num
							})
							.then((res) => {
								if (res.code == 1) {
									ElMessage({
										type: 'success',
										message: (num == 1 ? '同意' : '驳回') + '成功',
									})
									this.form.page = 1
									this.getList()
								}
							})
					})
					.catch(() => {
						// 取消
					})
			},
			// 退单原因
			seereason(val, tit) {
				ElMessageBox.confirm(
						val,
						tit, {
							confirmButtonText: '确定',
							type: 'warning',
							cancelButtonText: '取消'
						}
					)
					.then(() => {

					})
			},
			// 单独清空
			clearable(val) {
				this.form.val = ""
				this.form.page = 1
				this.getList()
			},
			// 表格全选
			handleSelectionChange(e) {
				this.idlist = []
				e.forEach(item => {
					this.idlist.push(item.id)
				})
			},
			// 清空
			reset() {
				for (let key in this.form) {
					this.form[key] = ''
				}
				this.form.page = 1
				this.form.limit = 15
				this.form.tab = 2
				this.getList()
			},
			// 搜索
			search() {
				this.form.page = 1
				this.getList()
			},
			handleSizeChange(e) {
				this.form.limit = e
				this.getList()
			},

			// 分页
			handleCurrentChange(e) {
				this.form.page = e
				this.getList()
			},
			// 获取列表
			getList() {
				this.tableloading = true
				this.$http.post(api.lists, this.form)
					.then((res) => {
						console.log(res)
						if (res.code == 1) {
							this.tableData = res.data.list
							this.total = res.data.count
							this.tableloading = false
						}
					})
			},
			// 诉求类型列表
			getListsq() {
				this.$http.post(api.getList)
					.then((res) => {
						if (res.code == 1) {
							this.sqoptions = res.data
						}
					})
			}
		}
	}
</script>

<style scoped>
	.text {
		padding: 20px;
		text-align: left;
	}

	.btn-b {
		margin-bottom: 10px;
	}
</style>
